@media (max-width: 1300px) {}

@media (max-width: 1120px) {}

@media (max-width: 992px) {
  .hero_area {
    min-height: auto;
  }

  .custom_nav-container .navbar-nav {
    padding-top: 15px;
padding-left: 0;
    align-items: center;
  }

  .user_option{
    justify-content: center;
    margin-top: 15px;
  }
  .custom_nav-container .navbar-nav .nav-item .nav-link {
    padding: 5px 25px;
    margin: 5px 0;
  }

  .slider_section .carousel-indicators {
    margin-top: 45px;
  }

  .offer_section .box {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .offer_section .box .img-box {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .OrPartition {
   
    width:100%
}
  .partitionText {
    position: relative;
    top: 0;
    padding-bottom: 30px;
    text-align: center;
    padding-top: 30px;
    width: 56px;
    left: 40%;
  }
  .formdivider,.adminPanel .formdivider {
   height: 1px;
    width: 100%;
    left: 0;
    top: 42px;
  }
  .testimonialSection{
    margin-top: 90px;
  }
 

}

@media (max-width: 767px) {

  .about_section .detail-box,
  .book_section .form_container {
    margin-bottom: 45px;
  }

  .about_section .row {
    flex-direction: column-reverse;
  }

  .offer_section .box {
    flex-direction: row;
    text-align: left;
  }

  .offer_section .box .img-box {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .card-group .row>span {
    width: 50%;
}
  .card-group .row>span .foodImage {
    height: 208px;
  }
  .onlyForMobile{display:inline-block}
  .mt-40-mobile{margin-top: 40px;}
}

@media (max-width: 576px) {}

@media (max-width: 480px) {}

@media (max-width: 430px) {
  /* .offer_section .box {
    flex-direction: column;
    text-align: center;
  } */

  .offer_section .box .img-box {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 376px) {
  .slider_section .detail-box h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}


@media (min-width:576px) and (max-width:991px) {
  .rent_solution_container .col-md-4.col-sm-6 {
    float: left;
    width: 50%;
  }
}